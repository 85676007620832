<template>
  <ion-page>
    <ion-header
      :translucent="true"
      class="ion-no-border">
      <ion-toolbar>
        <!-- <ion-buttons slot="start">
          <ion-back-button
            :default-href="pageDefaultBackLink" />
        </ion-buttons>
        <ion-title>{{ props.pageTitle }}</ion-title>
        <ion-buttons slot="end">
          <slot name="actions-end"></slot>
        </ion-buttons> -->
        <ion-buttons
          slot="end"
          class="btn">
          <slot name="actions-end"></slot>
        </ion-buttons>
        <div class="l-top">
          <h1
            class="logo main-logo"
            v-if="props.pageTitle === 'main'">
            <ion-img :src="require('../../../../public/assets/images/img_nodata.png')" />
          </h1>
          <h1
            class="logo"
            v-else>
            <ion-img :src="require('../../../../public/assets/images/simple_logo.png')" />
            <ion-title>{{ props.pageTitle }}</ion-title>
          </h1>
          
          <div
            class="d-day"
            v-if="props.showSubTitle">
            <p>종료일 <span>D{{ props.dDay }}</span>{{ props.endDate }}</p>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div
      class="sub-header"
      v-if="props.showSubTitle">
      <!-- <div class="d-day">
        <p>종료일 <span>D-{{ props.dDay }}</span>{{ props.endDate }}</p>
      </div> -->
      <div class="time">
        <p :class="`time-set ${state}`">
          <ion-icon :icon="timeOutline" />
          <span>{{ props.endTime }}</span>
        </p>
        <div class="share">
          <ion-button
            shape="round"
            @click="handleShareTwitter">
            <ion-icon
              slot="icon-only"
              :icon="shareSocialOutline" />
          </ion-button>
        </div>
      </div>
    </div>
   
    <ion-content>
      <slot></slot>
    </ion-content>
    <ion-footer v-if="props.useFooter">
      <ion-tabs>
        <ion-router-outlet />
        <ion-tab-bar slot="bottom">
          <ion-tab-button
            v-for="(item, index) in tabList"
            :key="index"
            :tab="item.tabName"
            :href="item.link"
            @click="handleTab(item)">
            <Vue3Lottie
              :ref="item.tabName"
              :animation-data="item.icon"
              :width="24"
              :height="24" 
              :auto-play="false" />
            <ion-label>{{ item.tabName }}</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </ion-footer>
  </ion-page>
</template>

<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
  IonButton,
  IonIcon,
  IonTabs, 
  IonRouterOutlet, 
  IonTabBar, 
  IonTabButton, 
  IonLabel,
  IonToggle,
  IonFooter,
  IonImg
} from '@ionic/vue'
import { shareSocialOutline, timeOutline } from 'ionicons/icons'
import { defineProps, ref } from 'vue'
import AnimationHome from '../../../theme/lotties/animationHome.json'
import AnimationVote from '../../../theme/lotties/animationVote.json'
import AnimationBoard from '../../../theme/lotties/animationBoard.json'
import AnimationService from '../../../theme/lotties/animationService.json'
import AnimationMy from '../../../theme/lotties/animationMy.json'
import { getpointSave } from '@/api/vote/point'
import { getToken } from '@/utils/auth'
//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const props = defineProps({
  pageTitle: { type: String, default: undefined },
  pageDefaultBackLink: { type: String, default: undefined },
  showSubTitle: { type: Boolean, default: false },
  dDay: { type: [String, Number], default: undefined },
  endDate: { type: String, default: undefined },
  endTime: { type: String, default: undefined },
  useFooter: { type: Boolean, default: true }
})
const token = getToken()
const home = ref()
const vote = ref()
const board = ref()
// const service = ref()
const my = ref()

const state = ref('')

const tabList = ref([
  {
    tabName: 'Home',
    link: '/main',
    icon: AnimationHome
  },
  {
    tabName: 'Vote',
    link: '/vote',
    icon: AnimationVote
  },
  {
    tabName: 'Board',
    link: '/board',
    icon: AnimationBoard
  },
  // {
  //   tabName: 'Board',
  //   link: '/board',
  //   icon: AnimationBoard
  // },
  {
    tabName: 'Estimate',
    link: '/cost',
    icon: AnimationService
  },
  {
    tabName: 'Service',
    link: '/service',
    icon: AnimationService
  },
  {
    tabName: 'My',
    link: '/my',
    icon: AnimationMy
  }
])

const handleShareTwitter = () => {
  const tweetText = '[valuesplay] \n https://www.valuesplay.com \n #인기언어 선택하세요 #가치플레이'

  // 트위터 공유 URL 생성
  const tweetUrl = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(tweetText)

  // 새 창으로 트위터 공유 페이지 열기
  window.open(tweetUrl, '_blank')

  // API 호출
  if(token){
    const pointParms = {
      point_category:3
    }
    getpointSave(pointParms)
  }
}

const handleTab = (item) => {
  if(item.tabName === 'home') {
    vote.value[0].stop()
    board.value[0].stop()
    // service.value[0].stop()
    my.value[0].stop()

    home.value[0].play()
    setTimeout(() => {
      home.value[0].pause()
    }, 1000)
  } else if(item.tabName === 'vote'){
    home.value[0].stop()
    board.value[0].stop()
    // service.value[0].stop()
    my.value[0].stop()

    vote.value[0].play()
    setTimeout(() => {
      vote.value[0].pause()
    }, 1000)
  } else if(item.tabName === 'board'){
    home.value[0].stop()
    vote.value[0].stop()
    // service.value[0].stop()
    my.value[0].stop()
    
    board.value[0].play()
    setTimeout(() => {
      board.value[0].pause()
    }, 1000)
  } /* else if(item.tabName === 'service'){
    home.value[0].stop()
    vote.value[0].stop()
    board.value[0].stop()
    my.value[0].stop()
    
    service.value[0].play()
    setTimeout(() => {
      service.value[0].pause()
    }, 1000)
  } */ else if(item.tabName === 'my'){
    home.value[0].stop()
    vote.value[0].stop()
    board.value[0].stop()
    // service.value[0].stop()
    
    my.value[0].play()
    setTimeout(() => {
      my.value[0].pause()
    }, 1000)
  }
}

//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------


</script>

<style scoped>
.icon{
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
}
ion-tab-button {
  text-transform: capitalize;
}

.share ion-button{
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 25px;
  height: 25px;
  --padding-start: 5px;
  --padding-end: 5px;
  --background: #55ACEE;
}
</style>