import { createRouter, createWebHistory } from '@ionic/vue-router'

const routes = [
  {
    path: '/main',
    name: 'main',
    component: () => import('@/views/Main.vue')
  },
 
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/vote',
    name: 'vote',
    component: () => import('@/views/vote/Vote.vue')
  },
  {
    path: '/vote/:id',
    name: 'voteSub',
    component: () => import('@/views/vote/VoteSub.vue')
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('@/views/my/My.vue')
  },
  {
    path: '/my-select',
    name: 'mySelect',
    component: () => import('@/views/my/MySelect.vue')
  },
  {
    path: '/my-setting',
    name: 'mySetting',
    component: () => import('@/views/my/MySetting.vue')
  },
  {
    path: '/my-heart',
    name: 'myHeart',
    component: () => import('@/views/my/MyHeart.vue')
  },
  {
    path: '/board',
    name: 'board',
    component: () => import('@/views/board/Board.vue')
  },
  {
    path: '/board-write',
    name: 'boardWrite',
    component: () => import('@/views/board/BoardWrite.vue')
  },
  {
    path: '/board/:id',
    name: 'boardComment',
    component: () => import('@/views/board/BoardComment.vue')
  },
  {
    path: '/board/:id/reply-write',
    name: 'replyWrite',
    component: () => import('@/views/board/BoardWrite.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/service/Service.vue')
  },
  {
    path: '/join-id',
    name: 'joinId',
    component: () => import('@/views/join/JoinId.vue')
  },
  {
    path: '/join-name',
    name: 'joinName',
    component: () => import('@/views/join/JoinName.vue')
  },
  {
    path: '/join-gender',
    name: 'joinGender',
    component: () => import('@/views/join/JoinGender.vue')
  },
  {
    path: '/join-mobile',
    name: 'joinMobile',
    component: () => import('@/views/join/JoinMobile.vue')
  },
  {
    path: '/join-password',
    name: 'joinPassword',
    component: () => import('@/views/join/JoinPassword.vue')
  },
  {
    path: '/join-password',
    name: 'joinPassword',
    component: () => import('@/views/join/JoinPassword.vue')
  },
  {
    path: '/join-check',
    name: 'joinCheck',
    component: () => import('@/views/join/JoinCheck.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/Faq/Faq.vue')
  },
  {
    path: '/cost',
    name: 'cost',
    component: () => import('@/views/Cost/Cost.vue')
  }
  // 샘플 //////////////////////////////////////////////////////
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes 
})

export default router
